export enum FORM_FIELDS {
  CONTRACTOR_LEGAL_STATUS = 'contractor_legal_status',
  OTHER_REGISTRATION_NUMBER = 'other_registration_number',
  OTHER_COUNTRY = 'other_country',
  OTHER_PAYMENT_NUMBER = 'other_payment_number',
  TAX_NUMBER = 'tax_number',
  RU_NAME = 'ru_name',
  PHONE_NUMBER = 'phone_number',
  RU_INN = 'ru_inn',
  RU_KPP = 'ru_kpp',
  RU_CEO = 'ru_ceo',
  RU_LEGAL_ADDRESS = 'ru_legal_address',
  OKVED = 'okved',
  OKVED_CODES = 'okved_codes',
}

export enum COUNTRY_SELECT {
  RUSSIAN = 'russia',
  OTHER = 'other',
}

export enum CONTRACTOR_TYPE {
  EXECUTOR = 'executor',
  ADVERTISER = 'advertiser',
}

export enum NetworkAccountsTypes {
  YANDEX_ACCOUNT = 'yandex_account',
  VKADS_ACCOUNT = 'vkads_account',
  VK_ACCOUNT = 'vk_account',
  MYTARGET_ACCOUNT = 'mytarget_account',
  TELEGRAMADS_ACCOUN = 'telegramads_account',
}

export enum AdsMarkingTabType {
  ACCOUNTS = 'accounts',
  CONTRACTS = 'contracts',
  COUNTERPARTIES = 'counterparties',
}
